import bowser from "bowser";

const browser = bowser.getParser(window.navigator.userAgent).parsedResult;

export default () => {
  return (
    ["mobile", "tablet"].includes(browser.platform.type) ||
    // iPadOS 13+ serves the desktop Safari UA, so we need an additional check
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  );
};
